import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { StickyContainer, Sticky } from 'react-sticky';

import Header from './components/Header'
import Inicio from './components/Inicio'
import Secciones from './components/Secciones'
import Noticias from './components/Noticias'
import Contacto from './components/Contacto'
import Menu from './components/Menu'
import Pie from './components/Pie'

import Login from './components/Login'
import Panel from './components/Panel'

/*
import ReactGA from 'react-ga';
const TRACKING_ID = "G-2DNLCC5ZKP"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
*/

function App() {
  /*
  let initialized = false
  useEffect(() => {
    if(!initialized){
      ReactGA.pageview(window.location.pathname + window.location.search);
      initialized = true //eslint-disable-line
    }
  }, []);
  */

  return (
    <Router>
      <Header />
      <StickyContainer>
        <Sticky>
          {({
            style,
            isSticky,
            wasSticky,
            distanceFromTop,
            distanceFromBottom,
            calculatedHeight
          }) => (
            <header style={style} className="menu_f">
              <Menu />
            </header>
          )}
        </Sticky>
          <Switch>
            <Route exact path="/" component={Inicio} />
            <Route path={window.path+"/inicio"} component={Inicio} />
            <Route path={window.path+"/secciones/:id"} component={Secciones} />
            <Route path={window.path+"/noticias/:id"} component={Noticias} />
            <Route path={window.path+"/contacto"} component={Contacto} />

            <Route path={window.path+"/admin"} component={Login} />
            {localStorage._ROL==='ADMIN' ? <Route path={window.path+"/panel"} component={Panel} /> : null}
            <Route component={Inicio} />
          </Switch>
          <Pie/>
      </StickyContainer>
    </Router>
  );
}

export default App;