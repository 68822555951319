import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { Link as Ir } from 'react-scroll';

import { connect } from 'react-redux';
import verSecciones from './../redux/actionSecciones';
import verSeccionesTotal from './../redux/actionSeccionesTotal';
import verSeccionesDestacado from './../redux/actionSeccionesDestacado';
import verSeccionesCantidad from './../redux/actionSeccionesCantidad';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Menu extends Component {
  componentWillMount () {
    window.H5_loading.show();
    if((window.location.href.substr(0, 5)==='http:' || window.location.href.substr(0, 3)==='www') && window.location.href!=='http://localhost:3000/'){
      window.location.href = window.rutas;
    }
  }

  componentDidMount () {
    window.H5_loading.hide();
  }

  activa(e) {
    this.props.mostrarSeccionesCantidad(0)
    this.listaTodas(e);
    this.listaDestacado(e);

    window.scrollTo(0, 0)
  }

  listaTodas(e) {
    window.H5_loading.show();
    var params = new URLSearchParams();
    params.append('c_tipo', e);
    params.append('inicial', 0);
    params.append('cual', 'secciones');

    Axios.post(`${window.ws}wsPublica.php`, params)
      .then(response => {
        if(response.data.elError===1){
          //this.setState({listas_all: []})
          this.props.mostrarSecciones([])
          console.log(response.data.mensaje)
        }else{
          //this.setState({listas_all: response.data.lista_array})
          this.props.mostrarSecciones(response.data.lista_array)
        }
      })
      .catch(error => {
        console.log(error)
      })
      .then(function() {
          window.H5_loading.hide();
      })
  }

  listaDestacado(e) {
    window.H5_loading.show();
    var params = new URLSearchParams();
    params.append('c_tipo', e);
    params.append('cual', 'secciones_destacado');

    Axios.post(`${window.ws}wsPublica.php`, params)
      .then(response => {      
        //this.setState({total_res: response.data.total_res})
        this.props.mostrarSeccionesTotal(response.data.total_res-response.data.lista_array.length)
        if(response.data.elError===1){
          //this.setState({listas_destacado: []})
          this.props.mostrarSeccionesDestacado([])
          console.log(response.data.mensaje)
        }else{
          //this.setState({listas_destacado: response.data.lista_array})
          this.props.mostrarSeccionesDestacado(response.data.lista_array)
        }
      })
      .catch(error => {
        console.log(error)
      })
      .then(function() {
          window.H5_loading.hide();
      })
  }

  render () {
    return (  
      <div className="menu_f" data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine">
        <div className="max_width padding_men">
          <div className="menu_flex">
            <Link to="/">Inicio</Link>
            {
              window._my_secciones.filter(p => p.destacada===1 && !p.no).map((item, i) =>
                <Link to={`../secciones/${item.titulo}`} key={i} onClick={this.activa.bind(this, item.titulo)}>{item.titulo}</Link>
              )
            }
          </div>
          <Ir to="inicio" activeClass="active" spy={true} smooth={true} duration={500} title="Subir" className="subir_menu">▲</Ir>
        </div>
      </div>
    )
  }
}

//export default Menu
const mapStateToProps = (state) => {
  return {
    verSecciones: state.verSecciones,
    verSeccionesTotal: state.verSeccionesTotal,
    verSeccionesDestacado: state.verSeccionesDestacado,
    verSeccionesCantidad: state.verSeccionesCantidad
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    mostrarSecciones: (variable) => dispatch(verSecciones(variable)),
    mostrarSeccionesTotal: (variable) => dispatch(verSeccionesTotal(variable)),
    mostrarSeccionesDestacado: (variable) => dispatch(verSeccionesDestacado(variable)),
    mostrarSeccionesCantidad: (variable) => dispatch(verSeccionesCantidad(variable))
  }
}

const Liga = Menu;
export default connect(mapStateToProps,mapDispatchToProps)(Liga);